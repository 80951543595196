// DecentCMS (c) 2014 Bertrand Le Roy, under MIT. See LICENSE.txt for licensing details.

// Overrides
$body-bg: #EEE;
$body-color: #444;
$dark: #69625E;
$dark-color: #D3D3D3;
$dark-link-color: #EEE;
$link-color: #6c757d;
$link-hover-color: darken($link-color, 15%);
$link-decoration: underline;
$link-hover-decoration: underline;
$component-active-bg: $link-hover-color;
$font-family-base: 'Sorts Mill Goudy', serif;
$font-family-titles: 'IM Fell English SC', serif;
$font-size-base: 1.125rem;
$line-height-base: 1.8;
$spacer: 1.5rem;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base * 0.9;

// Required
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Customizations
$background: white;

body {
    padding: 0;
    a {
        text-decoration-style: dotted
    }
    a:hover {
        text-decoration-style: solid;
    }
}

nav.navbar {
    margin: 0 0 2rem 0;
    padding: 0.75rem 15px;
    background: $background;

    a.navbar-brand {
        font-family: $font-family-titles;
        font-size: 2.4rem;
        text-decoration: none;
    }

    .nav-item {
        text-transform: uppercase;
        padding: 0;
        a.nav-link {
            text-decoration: none;
            padding: 0;
            margin-left: 2em;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

h1,h2,h3,h4,h5,h6 {
    font-family: $font-family-titles;
    a {
        color: $body-color;
    }
}

#outermost {
    background: $background;
    padding: 0 2% 2% 1em;
    #content {
        background-color: $background;
        .main {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 1em;
            img {
                @include img-fluid;
            }
            h1 a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

footer.main-footer{
    padding-left: 0;
    padding-right: 0;
    section {
        padding: 0.5rem;
        p {
            margin: 0;
            color: $dark-color;
            font-family: $font-family-titles;
            font-size: 0.9rem;
            a {
                color: $dark-link-color;
            }
        }
    }
}

.post-entry {
    blockquote {
        font-size: 1em;
        margin-left: 3em;
        font-style: italic;
    }

    img[align="right"] {
        margin-left: 1em !important;
    }

    img[align="left"] {
        margin-right: 1em !important;
    }
}

.post-meta {
    font-size: 0.8em;
}

.skip-navigation {
    @include sr-only;
    @include sr-only-focusable;
}

.page-item .page-link {
    padding: 0.2rem;
    border: none;
}

.fortyfive {
    -webkit-transform: translate(-2px,2px) rotate(45deg);
    -moz-transform: translate(-2px,2px) rotate(45deg);
    -o-transform: translate(-2px,2px) rotate(45deg);
    transform: translate(-2px,2px) rotate(45deg);
}

.btn-outline-primary {
    border-color: $gray-800 !important;
}

.btn-outline-secondary {
    border-color: $gray-400 !important;
}

.tag-cloud, .archives {
    font-size: 0.8em;
    .tag-bucket-1 {font-size: 1.1em;}
    .tag-bucket-2 {font-size: 1.2em;}
    .tag-bucket-3 {font-size: 1.3em;}
    .tag-bucket-4 {font-size: 1.4em;}
    .tag-bucket-5 {font-size: 1.5em;}
    a {
        text-decoration: none;
    }
}

// Comments
#archived-comments {
    margin: 24px 0 0 0;
    padding: 0;
    .archived-comments {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            margin: 12px 0;
            padding: 12px;
            border-bottom: 1px dotted $dark-color;
            .comment {
                margin: 0;
                padding: 0;
                font-size: 0.95em;
                h4 {
                    font-size: 1.5em;
                }
                .comment-who {
                    font-size: 0.9em;
                }
            }
        }
    }
}

// Optional

@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
// @import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
// @import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
// @import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/input-group";
// @import "../node_modules/bootstrap/scss/custom-forms";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
// @import "../node_modules/bootstrap/scss/card";
// @import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
// @import "../node_modules/bootstrap/scss/badge";
// @import "../node_modules/bootstrap/scss/jumbotron";
// @import "../node_modules/bootstrap/scss/alert";
// @import "../node_modules/bootstrap/scss/progress";
// @import "../node_modules/bootstrap/scss/media";
// @import "../node_modules/bootstrap/scss/list-group";
// @import "../node_modules/bootstrap/scss/close";
// @import "../node_modules/bootstrap/scss/toasts";
// @import "../node_modules/bootstrap/scss/modal";
// @import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
// @import "../node_modules/bootstrap/scss/carousel";
// @import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/print";
